import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: '/',
    component: () => import( '../views/TopView.vue')
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import( '../views/TopView.vue')
  },
  {
    path: '/csv',
    name: 'csv',
    component: () => import( '../views/CsvView.vue')
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/',
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
